@use 'mdsass' as util;
@use 'mdsass-theme-material-you' as you;

@mixin calendar {
	user-select: none;
	tr {
		padding-inline: 0;
		@include util.block;
		@media (max-width: 339px) {
			margin-inline: auto;
		}
		@media (max-width: 359px) {
			column-gap: 4px;
		}
	}
	th, td {
		text-align: center;
		overflow: hidden;
		width: 40px;
	}
	th span, td button {
		display: inline-flex;
		width: 40px;
		height: 40px;
		padding: 0;
		flex: 0 0 auto;
		justify-content: center;
		align-items: center;
	}
	td button {
		outline-color: transparent;
		&:not([disabled]) {
			background-color: var(--md-extended-color-pink-color-container);
			color: var(--md-extended-color-pink-on-color-container);
		}
	}

	td[data-today] button {
		//color: var(--md-sys-color-primary);
		outline-color: var(--md-extended-color-pink-color);
	}

	td[data-active] button {
		background-color: var(--md-extended-color-pink-color);
		color: var(--md-extended-color-pink-on-color);
	}

	td[data-done] button {
		text-decoration: line-through;
	}
}
