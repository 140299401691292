@use 'mdsass' as util;

@mixin _base {
	@include util.block;

	user-select: none;
	border: none;
	height: 40px;
	white-space: nowrap;
	@include util.apply-typescale('md-sys-typescale-label-large');
	border-radius: 100000px;
	flex-flow: row;
	align-items: center;
	justify-content: center;
	padding-block: 0;
	padding-inline-start: 24px;
	padding-inline-end: 24px;
	text-decoration: none;
	cursor: default;
	background-color: transparent;
	outline-color: transparent;
	color: inherit;

	&[disabled], &[aria-disabled="true"] {
		background-color: transparent;
		outline-color: transparent;
	}

	&:where(input[type="file"]) {
		display: block;
		line-height: 40px;
	}
	&:where(input[type="file"])::file-selector-button {
		appearance: none;
		margin: 0;
		padding: 0;
		padding-inline-end: 0.5rem;
		font: inherit;
		color: inherit;
		background: none;
		border: none;
	}
}

@mixin button-filled {
	@include _base();
	background-color: var(--md-sys-color-primary);
	color: var(--md-sys-color-on-primary);

	&[disabled], &[aria-disabled="true"] {
		background-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
		color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
	}
}

@mixin button-filled-tonal {
	@include _base();
	background-color: var(--md-sys-color-secondary-container);
	color: var(--md-sys-color-on-secondary-container);

	&[disabled], &[aria-disabled="true"] {
		background-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
		color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
	}
}

@mixin button-outlined {
	@include _base();
	outline-color: var(--md-sys-color-outline);
	color: var(--md-sys-color-primary);

	&[disabled], &[aria-disabled="true"] {
		outline-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
		color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
	}
}

@mixin button-text {
	@include _base();
	color: var(--md-sys-color-primary);
	padding-inline-start: 12px;
	padding-inline-end: 12px;

	&[disabled], &[aria-disabled="true"] {
		color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
	}
}
