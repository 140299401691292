@use 'mdsass' as util;

:root {
	--md-ref-typeface-brand: 'Roboto', sans-serif;
	--md-ref-typeface-plain: 'Roboto', sans-serif;
	--md-ref-typeface-weight-regular: 400;
	--md-ref-typeface-weight-medium: 500;
	--md-ref-typeface-weight-bold: 700;

	@include util.declare-typescale('md-sys-typescale-label-small', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-medium), var(--md-ref-typeface-weight-bold), 11, 16, 0.5);
	@include util.declare-typescale('md-sys-typescale-label-medium', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-medium), var(--md-ref-typeface-weight-bold), 12, 16, 0.5);
	@include util.declare-typescale('md-sys-typescale-label-large', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-medium), var(--md-ref-typeface-weight-bold), 14, 20, 0.1);

	@include util.declare-typescale('md-sys-typescale-body-small', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 12, 16, 0.4);
	@include util.declare-typescale('md-sys-typescale-body-medium', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 14, 20, 0.25);
	@include util.declare-typescale('md-sys-typescale-body-large', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 16, 24, 0.5);

	@include util.declare-typescale('md-sys-typescale-title-small', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-medium), var(--md-ref-typeface-weight-bold), 14, 20, 0.1);
	@include util.declare-typescale('md-sys-typescale-title-medium', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-medium), var(--md-ref-typeface-weight-bold), 16, 24, 0.15);
	@include util.declare-typescale('md-sys-typescale-title-large', var(--md-ref-typeface-plain), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 22, 28, 0);

	@include util.declare-typescale('md-sys-typescale-headline-small', var(--md-ref-typeface-brand), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 24, 32, 0);
	@include util.declare-typescale('md-sys-typescale-headline-medium', var(--md-ref-typeface-brand), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 28, 36, 0);
	@include util.declare-typescale('md-sys-typescale-headline-large', var(--md-ref-typeface-brand), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 32, 40, 0);

	@include util.declare-typescale('md-sys-typescale-display-small', var(--md-ref-typeface-brand), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 36, 44, 0);
	@include util.declare-typescale('md-sys-typescale-display-medium', var(--md-ref-typeface-brand), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 45, 52, 0);
	@include util.declare-typescale('md-sys-typescale-display-large', var(--md-ref-typeface-brand), var(--md-ref-typeface-weight-regular), var(--md-ref-typeface-weight-bold), 57, 64, -0.25);

	color-scheme: only light;

	--md-sys-color-primary: rgb(72 93 146);
	--md-sys-color-surface-tint: rgb(72 93 146);
	--md-sys-color-on-primary: rgb(255 255 255);
	--md-sys-color-primary-container: rgb(218 226 255);
	--md-sys-color-on-primary-container: rgb(0 25 70);
	--md-sys-color-secondary: rgb(88 94 113);
	--md-sys-color-on-secondary: rgb(255 255 255);
	--md-sys-color-secondary-container: rgb(220 226 249);
	--md-sys-color-on-secondary-container: rgb(21 27 44);
	--md-sys-color-tertiary: rgb(115 85 114);
	--md-sys-color-on-tertiary: rgb(255 255 255);
	--md-sys-color-tertiary-container: rgb(254 215 250);
	--md-sys-color-on-tertiary-container: rgb(42 18 44);
	--md-sys-color-error: rgb(186 26 26);
	--md-sys-color-on-error: rgb(255 255 255);
	--md-sys-color-error-container: rgb(255 218 214);
	--md-sys-color-on-error-container: rgb(65 0 2);
	--md-sys-color-background: rgb(250 248 255);
	--md-sys-color-on-background: rgb(26 27 33);
	--md-sys-color-surface: rgb(250 248 255);
	--md-sys-color-on-surface: rgb(26 27 33);
	--md-sys-color-surface-variant: rgb(225 226 236);
	--md-sys-color-on-surface-variant: rgb(68 70 79);
	--md-sys-color-outline: rgb(117 119 128);
	--md-sys-color-outline-variant: rgb(197 198 208);
	--md-sys-color-shadow: rgb(0 0 0);
	--md-sys-color-scrim: rgb(0 0 0);
	--md-sys-color-inverse-surface: rgb(47 48 54);
	--md-sys-color-inverse-on-surface: rgb(241 240 247);
	--md-sys-color-inverse-primary: rgb(177 197 255);
	--md-sys-color-primary-fixed: rgb(218 226 255);
	--md-sys-color-on-primary-fixed: rgb(0 25 70);
	--md-sys-color-primary-fixed-dim: rgb(177 197 255);
	--md-sys-color-on-primary-fixed-variant: rgb(47 69 120);
	--md-sys-color-secondary-fixed: rgb(220 226 249);
	--md-sys-color-on-secondary-fixed: rgb(21 27 44);
	--md-sys-color-secondary-fixed-dim: rgb(192 198 220);
	--md-sys-color-on-secondary-fixed-variant: rgb(64 70 89);
	--md-sys-color-tertiary-fixed: rgb(254 215 250);
	--md-sys-color-on-tertiary-fixed: rgb(42 18 44);
	--md-sys-color-tertiary-fixed-dim: rgb(224 187 221);
	--md-sys-color-on-tertiary-fixed-variant: rgb(89 61 89);
	--md-sys-color-surface-dim: rgb(218 217 224);
	--md-sys-color-surface-bright: rgb(250 248 255);
	--md-sys-color-surface-container-lowest: rgb(255 255 255);
	--md-sys-color-surface-container-low: rgb(244 243 250);
	--md-sys-color-surface-container: rgb(238 237 244);
	--md-sys-color-surface-container-high: rgb(232 231 239);
	--md-sys-color-surface-container-highest: rgb(226 226 233);

	@media (prefers-color-scheme: dark) {
		color-scheme: only dark;

		--md-sys-color-primary: rgb(177 197 255);
		--md-sys-color-surface-tint: rgb(177 197 255);
		--md-sys-color-on-primary: rgb(22 46 96);
		--md-sys-color-primary-container: rgb(47 69 120);
		--md-sys-color-on-primary-container: rgb(218 226 255);
		--md-sys-color-secondary: rgb(192 198 220);
		--md-sys-color-on-secondary: rgb(42 48 66);
		--md-sys-color-secondary-container: rgb(64 70 89);
		--md-sys-color-on-secondary-container: rgb(220 226 249);
		--md-sys-color-tertiary: rgb(224 187 221);
		--md-sys-color-on-tertiary: rgb(65 39 66);
		--md-sys-color-tertiary-container: rgb(89 61 89);
		--md-sys-color-on-tertiary-container: rgb(254 215 250);
		--md-sys-color-error: rgb(255 180 171);
		--md-sys-color-on-error: rgb(105 0 5);
		--md-sys-color-error-container: rgb(147 0 10);
		--md-sys-color-on-error-container: rgb(255 218 214);
		--md-sys-color-background: rgb(18 19 24);
		--md-sys-color-on-background: rgb(226 226 233);
		--md-sys-color-surface: rgb(18 19 24);
		--md-sys-color-on-surface: rgb(226 226 233);
		--md-sys-color-surface-variant: rgb(68 70 79);
		--md-sys-color-on-surface-variant: rgb(197 198 208);
		--md-sys-color-outline: rgb(143 144 153);
		--md-sys-color-outline-variant: rgb(68 70 79);
		--md-sys-color-shadow: rgb(0 0 0);
		--md-sys-color-scrim: rgb(0 0 0);
		--md-sys-color-inverse-surface: rgb(226 226 233);
		--md-sys-color-inverse-on-surface: rgb(47 48 54);
		--md-sys-color-inverse-primary: rgb(72 93 146);
		--md-sys-color-primary-fixed: rgb(218 226 255);
		--md-sys-color-on-primary-fixed: rgb(0 25 70);
		--md-sys-color-primary-fixed-dim: rgb(177 197 255);
		--md-sys-color-on-primary-fixed-variant: rgb(47 69 120);
		--md-sys-color-secondary-fixed: rgb(220 226 249);
		--md-sys-color-on-secondary-fixed: rgb(21 27 44);
		--md-sys-color-secondary-fixed-dim: rgb(192 198 220);
		--md-sys-color-on-secondary-fixed-variant: rgb(64 70 89);
		--md-sys-color-tertiary-fixed: rgb(254 215 250);
		--md-sys-color-on-tertiary-fixed: rgb(42 18 44);
		--md-sys-color-tertiary-fixed-dim: rgb(224 187 221);
		--md-sys-color-on-tertiary-fixed-variant: rgb(89 61 89);
		--md-sys-color-surface-dim: rgb(18 19 24);
		--md-sys-color-surface-bright: rgb(56 57 63);
		--md-sys-color-surface-container-lowest: rgb(13 14 19);
		--md-sys-color-surface-container-low: rgb(26 27 33);
		--md-sys-color-surface-container: rgb(30 31 37);
		--md-sys-color-surface-container-high: rgb(40 42 47);
		--md-sys-color-surface-container-highest: rgb(51 52 58);
	}

	scrollbar-color: var(--md-sys-color-secondary) var(--md-sys-color-surface-container-lowest);
}
