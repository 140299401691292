:root {
  --md-ref-typeface-brand: "Roboto", sans-serif;
  --md-ref-typeface-plain: "Roboto", sans-serif;
  --md-ref-typeface-weight-regular: 400;
  --md-ref-typeface-weight-medium: 500;
  --md-ref-typeface-weight-bold: 700;
  --md-sys-typescale-label-small--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-label-small--weight: var(--md-ref-typeface-weight-medium);
  --md-sys-typescale-label-small--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-label-small--size: .6875rem;
  --md-sys-typescale-label-small--line-height: 1rem;
  --md-sys-typescale-label-small--tracking: .03125rem;
  --md-sys-typescale-label-medium--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-label-medium--weight: var(--md-ref-typeface-weight-medium);
  --md-sys-typescale-label-medium--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-label-medium--size: .75rem;
  --md-sys-typescale-label-medium--line-height: 1rem;
  --md-sys-typescale-label-medium--tracking: .03125rem;
  --md-sys-typescale-label-large--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-label-large--weight: var(--md-ref-typeface-weight-medium);
  --md-sys-typescale-label-large--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-label-large--size: .875rem;
  --md-sys-typescale-label-large--line-height: 1.25rem;
  --md-sys-typescale-label-large--tracking: .00625rem;
  --md-sys-typescale-body-small--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-body-small--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-body-small--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-body-small--size: .75rem;
  --md-sys-typescale-body-small--line-height: 1rem;
  --md-sys-typescale-body-small--tracking: .025rem;
  --md-sys-typescale-body-medium--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-body-medium--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-body-medium--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-body-medium--size: .875rem;
  --md-sys-typescale-body-medium--line-height: 1.25rem;
  --md-sys-typescale-body-medium--tracking: .015625rem;
  --md-sys-typescale-body-large--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-body-large--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-body-large--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-body-large--size: 1rem;
  --md-sys-typescale-body-large--line-height: 1.5rem;
  --md-sys-typescale-body-large--tracking: .03125rem;
  --md-sys-typescale-title-small--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-title-small--weight: var(--md-ref-typeface-weight-medium);
  --md-sys-typescale-title-small--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-title-small--size: .875rem;
  --md-sys-typescale-title-small--line-height: 1.25rem;
  --md-sys-typescale-title-small--tracking: .00625rem;
  --md-sys-typescale-title-medium--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-title-medium--weight: var(--md-ref-typeface-weight-medium);
  --md-sys-typescale-title-medium--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-title-medium--size: 1rem;
  --md-sys-typescale-title-medium--line-height: 1.5rem;
  --md-sys-typescale-title-medium--tracking: .009375rem;
  --md-sys-typescale-title-large--font: var(--md-ref-typeface-plain);
  --md-sys-typescale-title-large--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-title-large--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-title-large--size: 1.375rem;
  --md-sys-typescale-title-large--line-height: 1.75rem;
  --md-sys-typescale-title-large--tracking: 0rem;
  --md-sys-typescale-headline-small--font: var(--md-ref-typeface-brand);
  --md-sys-typescale-headline-small--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-headline-small--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-headline-small--size: 1.5rem;
  --md-sys-typescale-headline-small--line-height: 2rem;
  --md-sys-typescale-headline-small--tracking: 0rem;
  --md-sys-typescale-headline-medium--font: var(--md-ref-typeface-brand);
  --md-sys-typescale-headline-medium--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-headline-medium--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-headline-medium--size: 1.75rem;
  --md-sys-typescale-headline-medium--line-height: 2.25rem;
  --md-sys-typescale-headline-medium--tracking: 0rem;
  --md-sys-typescale-headline-large--font: var(--md-ref-typeface-brand);
  --md-sys-typescale-headline-large--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-headline-large--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-headline-large--size: 2rem;
  --md-sys-typescale-headline-large--line-height: 2.5rem;
  --md-sys-typescale-headline-large--tracking: 0rem;
  --md-sys-typescale-display-small--font: var(--md-ref-typeface-brand);
  --md-sys-typescale-display-small--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-display-small--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-display-small--size: 2.25rem;
  --md-sys-typescale-display-small--line-height: 2.75rem;
  --md-sys-typescale-display-small--tracking: 0rem;
  --md-sys-typescale-display-medium--font: var(--md-ref-typeface-brand);
  --md-sys-typescale-display-medium--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-display-medium--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-display-medium--size: 2.8125rem;
  --md-sys-typescale-display-medium--line-height: 3.25rem;
  --md-sys-typescale-display-medium--tracking: 0rem;
  --md-sys-typescale-display-large--font: var(--md-ref-typeface-brand);
  --md-sys-typescale-display-large--weight: var(--md-ref-typeface-weight-regular);
  --md-sys-typescale-display-large--weight-prominent: var(--md-ref-typeface-weight-bold);
  --md-sys-typescale-display-large--size: 3.5625rem;
  --md-sys-typescale-display-large--line-height: 4rem;
  --md-sys-typescale-display-large--tracking: -.015625rem;
  color-scheme: light only;
  --md-sys-color-primary: #485d92;
  --md-sys-color-surface-tint: #485d92;
  --md-sys-color-on-primary: #fff;
  --md-sys-color-primary-container: #dae2ff;
  --md-sys-color-on-primary-container: #001946;
  --md-sys-color-secondary: #585e71;
  --md-sys-color-on-secondary: #fff;
  --md-sys-color-secondary-container: #dce2f9;
  --md-sys-color-on-secondary-container: #151b2c;
  --md-sys-color-tertiary: #735572;
  --md-sys-color-on-tertiary: #fff;
  --md-sys-color-tertiary-container: #fed7fa;
  --md-sys-color-on-tertiary-container: #2a122c;
  --md-sys-color-error: #ba1a1a;
  --md-sys-color-on-error: #fff;
  --md-sys-color-error-container: #ffdad6;
  --md-sys-color-on-error-container: #410002;
  --md-sys-color-background: #faf8ff;
  --md-sys-color-on-background: #1a1b21;
  --md-sys-color-surface: #faf8ff;
  --md-sys-color-on-surface: #1a1b21;
  --md-sys-color-surface-variant: #e1e2ec;
  --md-sys-color-on-surface-variant: #44464f;
  --md-sys-color-outline: #757780;
  --md-sys-color-outline-variant: #c5c6d0;
  --md-sys-color-shadow: #000;
  --md-sys-color-scrim: #000;
  --md-sys-color-inverse-surface: #2f3036;
  --md-sys-color-inverse-on-surface: #f1f0f7;
  --md-sys-color-inverse-primary: #b1c5ff;
  --md-sys-color-primary-fixed: #dae2ff;
  --md-sys-color-on-primary-fixed: #001946;
  --md-sys-color-primary-fixed-dim: #b1c5ff;
  --md-sys-color-on-primary-fixed-variant: #2f4578;
  --md-sys-color-secondary-fixed: #dce2f9;
  --md-sys-color-on-secondary-fixed: #151b2c;
  --md-sys-color-secondary-fixed-dim: #c0c6dc;
  --md-sys-color-on-secondary-fixed-variant: #404659;
  --md-sys-color-tertiary-fixed: #fed7fa;
  --md-sys-color-on-tertiary-fixed: #2a122c;
  --md-sys-color-tertiary-fixed-dim: #e0bbdd;
  --md-sys-color-on-tertiary-fixed-variant: #593d59;
  --md-sys-color-surface-dim: #dad9e0;
  --md-sys-color-surface-bright: #faf8ff;
  --md-sys-color-surface-container-lowest: #fff;
  --md-sys-color-surface-container-low: #f4f3fa;
  --md-sys-color-surface-container: #eeedf4;
  --md-sys-color-surface-container-high: #e8e7ef;
  --md-sys-color-surface-container-highest: #e2e2e9;
  scrollbar-color: var(--md-sys-color-secondary) var(--md-sys-color-surface-container-lowest);
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark only;
    --md-sys-color-primary: #b1c5ff;
    --md-sys-color-surface-tint: #b1c5ff;
    --md-sys-color-on-primary: #162e60;
    --md-sys-color-primary-container: #2f4578;
    --md-sys-color-on-primary-container: #dae2ff;
    --md-sys-color-secondary: #c0c6dc;
    --md-sys-color-on-secondary: #2a3042;
    --md-sys-color-secondary-container: #404659;
    --md-sys-color-on-secondary-container: #dce2f9;
    --md-sys-color-tertiary: #e0bbdd;
    --md-sys-color-on-tertiary: #412742;
    --md-sys-color-tertiary-container: #593d59;
    --md-sys-color-on-tertiary-container: #fed7fa;
    --md-sys-color-error: #ffb4ab;
    --md-sys-color-on-error: #690005;
    --md-sys-color-error-container: #93000a;
    --md-sys-color-on-error-container: #ffdad6;
    --md-sys-color-background: #121318;
    --md-sys-color-on-background: #e2e2e9;
    --md-sys-color-surface: #121318;
    --md-sys-color-on-surface: #e2e2e9;
    --md-sys-color-surface-variant: #44464f;
    --md-sys-color-on-surface-variant: #c5c6d0;
    --md-sys-color-outline: #8f9099;
    --md-sys-color-outline-variant: #44464f;
    --md-sys-color-shadow: #000;
    --md-sys-color-scrim: #000;
    --md-sys-color-inverse-surface: #e2e2e9;
    --md-sys-color-inverse-on-surface: #2f3036;
    --md-sys-color-inverse-primary: #485d92;
    --md-sys-color-primary-fixed: #dae2ff;
    --md-sys-color-on-primary-fixed: #001946;
    --md-sys-color-primary-fixed-dim: #b1c5ff;
    --md-sys-color-on-primary-fixed-variant: #2f4578;
    --md-sys-color-secondary-fixed: #dce2f9;
    --md-sys-color-on-secondary-fixed: #151b2c;
    --md-sys-color-secondary-fixed-dim: #c0c6dc;
    --md-sys-color-on-secondary-fixed-variant: #404659;
    --md-sys-color-tertiary-fixed: #fed7fa;
    --md-sys-color-on-tertiary-fixed: #2a122c;
    --md-sys-color-tertiary-fixed-dim: #e0bbdd;
    --md-sys-color-on-tertiary-fixed-variant: #593d59;
    --md-sys-color-surface-dim: #121318;
    --md-sys-color-surface-bright: #38393f;
    --md-sys-color-surface-container-lowest: #0d0e13;
    --md-sys-color-surface-container-low: #1a1b21;
    --md-sys-color-surface-container: #1e1f25;
    --md-sys-color-surface-container-high: #282a2f;
    --md-sys-color-surface-container-highest: #33343a;
  }
}

:root {
  --md-ref-typeface-brand: "Ubuntu", sans-serif;
  --md-ref-typeface-plain: "Ubuntu", sans-serif;
  color-scheme: light only;
  --md-sys-color-primary: #66558f;
  --md-sys-color-surface-tint: #66558f;
  --md-sys-color-on-primary: #fff;
  --md-sys-color-primary-container: #e9ddff;
  --md-sys-color-on-primary-container: #210f47;
  --md-sys-color-secondary: #625b70;
  --md-sys-color-on-secondary: #fff;
  --md-sys-color-secondary-container: #e8def8;
  --md-sys-color-on-secondary-container: #1e192b;
  --md-sys-color-tertiary: #7e525f;
  --md-sys-color-on-tertiary: #fff;
  --md-sys-color-tertiary-container: #ffd9e2;
  --md-sys-color-on-tertiary-container: #31101d;
  --md-sys-color-error: #ba1a1a;
  --md-sys-color-on-error: #fff;
  --md-sys-color-error-container: #ffdad6;
  --md-sys-color-on-error-container: #410002;
  --md-sys-color-background: #fef7ff;
  --md-sys-color-on-background: #1d1b20;
  --md-sys-color-surface: #fef7ff;
  --md-sys-color-on-surface: #1d1b20;
  --md-sys-color-surface-variant: #e7e0eb;
  --md-sys-color-on-surface-variant: #49454e;
  --md-sys-color-outline: #7a757f;
  --md-sys-color-outline-variant: #cac4cf;
  --md-sys-color-shadow: #000;
  --md-sys-color-scrim: #000;
  --md-sys-color-inverse-surface: #322f35;
  --md-sys-color-inverse-on-surface: #f5eff7;
  --md-sys-color-inverse-primary: #d0bcfe;
  --md-sys-color-primary-fixed: #e9ddff;
  --md-sys-color-on-primary-fixed: #210f47;
  --md-sys-color-primary-fixed-dim: #d0bcfe;
  --md-sys-color-on-primary-fixed-variant: #4d3d75;
  --md-sys-color-secondary-fixed: #e8def8;
  --md-sys-color-on-secondary-fixed: #1e192b;
  --md-sys-color-secondary-fixed-dim: #ccc2db;
  --md-sys-color-on-secondary-fixed-variant: #4a4458;
  --md-sys-color-tertiary-fixed: #ffd9e2;
  --md-sys-color-on-tertiary-fixed: #31101d;
  --md-sys-color-tertiary-fixed-dim: #efb8c7;
  --md-sys-color-on-tertiary-fixed-variant: #633b48;
  --md-sys-color-surface-dim: #ded8e0;
  --md-sys-color-surface-bright: #fef7ff;
  --md-sys-color-surface-container-lowest: #fff;
  --md-sys-color-surface-container-low: #f6f1ff;
  --md-sys-color-surface-container: #f1ecf9;
  --md-sys-color-surface-container-high: #ebe6f3;
  --md-sys-color-surface-container-highest: #e5e0ed;
  --md-extended-color-pink-color: #864b70;
  --md-extended-color-pink-on-color: #fff;
  --md-extended-color-pink-color-container: #ffd8ec;
  --md-extended-color-pink-on-color-container: #37072a;
  --md-extended-color-blue-color: #156683;
  --md-extended-color-blue-on-color: #fff;
  --md-extended-color-blue-color-container: #bfe8ff;
  --md-extended-color-blue-on-color-container: #001f2b;
  scrollbar-color: #b8e2f9 #f6f1ff80;
  --gradient: linear-gradient(90deg, #fccbe5, #b8e2f9);
  --gradient-start: linear-gradient(90deg, #fccbe5, #dad7ef);
  --gradient-center: linear-gradient(90deg, #dad7ef, #dad7ef);
  --gradient-end: linear-gradient(90deg, #dad7ef, #b8e2f9);
}

@media (prefers-color-scheme: dark) {
  :root:not(.light-only) {
    color-scheme: dark only;
    --md-sys-color-primary: #d0bcfe;
    --md-sys-color-surface-tint: #d0bcfe;
    --md-sys-color-on-primary: #36265d;
    --md-sys-color-primary-container: #4d3d75;
    --md-sys-color-on-primary-container: #e9ddff;
    --md-sys-color-secondary: #ccc2db;
    --md-sys-color-on-secondary: #332d41;
    --md-sys-color-secondary-container: #4a4458;
    --md-sys-color-on-secondary-container: #e8def8;
    --md-sys-color-tertiary: #efb8c7;
    --md-sys-color-on-tertiary: #4a2531;
    --md-sys-color-tertiary-container: #633b48;
    --md-sys-color-on-tertiary-container: #ffd9e2;
    --md-sys-color-error: #ffb4ab;
    --md-sys-color-on-error: #690005;
    --md-sys-color-error-container: #93000a;
    --md-sys-color-on-error-container: #ffdad6;
    --md-sys-color-background: #141218;
    --md-sys-color-on-background: #e6e0e9;
    --md-sys-color-surface: #141218;
    --md-sys-color-on-surface: #e6e0e9;
    --md-sys-color-surface-variant: #49454e;
    --md-sys-color-on-surface-variant: #cac4cf;
    --md-sys-color-outline: #948f99;
    --md-sys-color-outline-variant: #49454e;
    --md-sys-color-shadow: #000;
    --md-sys-color-scrim: #000;
    --md-sys-color-inverse-surface: #e6e0e9;
    --md-sys-color-inverse-on-surface: #322f35;
    --md-sys-color-inverse-primary: #66558f;
    --md-sys-color-primary-fixed: #e9ddff;
    --md-sys-color-on-primary-fixed: #210f47;
    --md-sys-color-primary-fixed-dim: #d0bcfe;
    --md-sys-color-on-primary-fixed-variant: #4d3d75;
    --md-sys-color-secondary-fixed: #e8def8;
    --md-sys-color-on-secondary-fixed: #1e192b;
    --md-sys-color-secondary-fixed-dim: #ccc2db;
    --md-sys-color-on-secondary-fixed-variant: #4a4458;
    --md-sys-color-tertiary-fixed: #ffd9e2;
    --md-sys-color-on-tertiary-fixed: #31101d;
    --md-sys-color-tertiary-fixed-dim: #efb8c7;
    --md-sys-color-on-tertiary-fixed-variant: #633b48;
    --md-sys-color-surface-dim: #141218;
    --md-sys-color-surface-bright: #3b383e;
    --md-sys-color-surface-container-lowest: #0f0d13;
    --md-sys-color-surface-container-low: #1d1b20;
    --md-sys-color-surface-container: #211f24;
    --md-sys-color-surface-container-high: #2b292f;
    --md-sys-color-surface-container-highest: #36343a;
    --md-extended-color-pink-color: #f9b1db;
    --md-extended-color-pink-on-color: #501e40;
    --md-extended-color-pink-color-container: #6a3457;
    --md-extended-color-pink-on-color-container: #ffd8ec;
    --md-extended-color-blue-color: #8ccff1;
    --md-extended-color-blue-on-color: #003547;
    --md-extended-color-blue-color-container: #004d66;
    --md-extended-color-blue-on-color-container: #bfe8ff;
    scrollbar-color: #b8e2f9 #14121880;
  }
}

#bg {
  display: none;
}

:root.light-only #bg {
  z-index: -1;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0 0 auto;
  overflow: hidden;
}

@media not (prefers-color-scheme: dark) {
  #bg {
    z-index: -1;
    height: 100%;
    display: block;
    position: absolute;
    inset: 0 0 auto;
    overflow: hidden;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  font: inherit;
  overflow-wrap: inherit;
  background-repeat: no-repeat;
  background-origin: border-box;
  border: 0 solid #0000;
  gap: 8px;
  margin: 0;
  padding: 0;
}

html {
  overflow-wrap: break-word;
  background-color: var(--md-sys-color-background);
  color: var(--md-sys-color-on-background);
  --margin-inline-start: 16px;
  --margin-inline-end: 16px;
  --spacer: 24px;
  flex-flow: column;
  height: 100%;
  display: flex;
}

@media (width >= 600px) {
  html {
    --margin-inline-start: 24px;
    --margin-inline-end: 24px;
  }
}

body {
  font-family: var(--md-sys-typescale-label-medium--font);
  font-size: var(--md-sys-typescale-label-medium--size);
  font-weight: var(--md-sys-typescale-label-medium--weight);
  line-height: var(--md-sys-typescale-label-medium--line-height);
  letter-spacing: var(--md-sys-typescale-label-medium--tracking);
}

body strong {
  font-weight: var(--md-sys-typescale-label-medium--weight-prominent);
}

h1, h2 {
  font-family: var(--md-sys-typescale-headline-medium--font);
  font-size: var(--md-sys-typescale-headline-medium--size);
  font-weight: var(--md-sys-typescale-headline-medium--weight);
  line-height: var(--md-sys-typescale-headline-medium--line-height);
  letter-spacing: var(--md-sys-typescale-headline-medium--tracking);
}

h1 strong, h2 strong {
  font-weight: var(--md-sys-typescale-headline-medium--weight-prominent);
}

h3 {
  font-family: var(--md-sys-typescale-headline-small--font);
  font-size: var(--md-sys-typescale-headline-small--size);
  font-weight: var(--md-sys-typescale-headline-small--weight);
  line-height: var(--md-sys-typescale-headline-small--line-height);
  letter-spacing: var(--md-sys-typescale-headline-small--tracking);
}

h3 strong {
  font-weight: var(--md-sys-typescale-headline-small--weight-prominent);
}

h4 {
  font-family: var(--md-sys-typescale-title-large--font);
  font-size: var(--md-sys-typescale-title-large--size);
  font-weight: var(--md-sys-typescale-title-large--weight);
  line-height: var(--md-sys-typescale-title-large--line-height);
  letter-spacing: var(--md-sys-typescale-title-large--tracking);
}

h4 strong {
  font-weight: var(--md-sys-typescale-title-large--weight-prominent);
}

h5 {
  font-family: var(--md-sys-typescale-title-medium--font);
  font-size: var(--md-sys-typescale-title-medium--size);
  font-weight: var(--md-sys-typescale-title-medium--weight);
  line-height: var(--md-sys-typescale-title-medium--line-height);
  letter-spacing: var(--md-sys-typescale-title-medium--tracking);
}

h5 strong {
  font-weight: var(--md-sys-typescale-title-medium--weight-prominent);
}

h6 {
  font-family: var(--md-sys-typescale-title-small--font);
  font-size: var(--md-sys-typescale-title-small--size);
  font-weight: var(--md-sys-typescale-title-small--weight);
  line-height: var(--md-sys-typescale-title-small--line-height);
  letter-spacing: var(--md-sys-typescale-title-small--tracking);
}

h6 strong {
  font-weight: var(--md-sys-typescale-title-small--weight-prominent);
}

th, td, p, li, dt, dd {
  font-family: var(--md-sys-typescale-body-medium--font);
  font-size: var(--md-sys-typescale-body-medium--size);
  font-weight: var(--md-sys-typescale-body-medium--weight);
  line-height: var(--md-sys-typescale-body-medium--line-height);
  letter-spacing: var(--md-sys-typescale-body-medium--tracking);
}

th strong, td strong, p strong, li strong, dt strong, dd strong {
  font-weight: var(--md-sys-typescale-body-medium--weight-prominent);
}

aside p, small {
  font-family: var(--md-sys-typescale-body-small--font);
  font-size: var(--md-sys-typescale-body-small--size);
  font-weight: var(--md-sys-typescale-body-small--weight);
  line-height: var(--md-sys-typescale-body-small--line-height);
  letter-spacing: var(--md-sys-typescale-body-small--tracking);
  color: var(--md-sys-color-on-surface-variant);
}

aside p strong, small strong {
  font-weight: var(--md-sys-typescale-body-small--weight-prominent);
}

b {
  font-weight: bold;
}

i {
  font-style: italic;
}

a {
  color: var(--md-sys-color-primary);
}

body {
  flex: 1 1 0;
}

:where(body, body *) {
  outline-offset: -1px;
  outline: 1px solid #0000;
  flex-flow: column;
  display: flex;
}

[hidden] {
  display: none;
}

main nav {
  background: var(--gradient) bottom / 100% .225rem no-repeat border-box, linear-gradient(90deg, #f1ebff80, #f1ebff80);
  border-bottom-width: .225rem;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding-block: 8px;
}

:root.light-only main nav {
  backdrop-filter: blur(.3rem);
  background-color: #f1ebff80;
  box-shadow: 0 0 .5rem #0000001a;
}

@media not (prefers-color-scheme: dark) {
  main nav {
    backdrop-filter: blur(.3rem);
    background-color: #f1ebff80;
    box-shadow: 0 0 .5rem #0000001a;
  }
}

p {
  text-align: justify;
}

script, style {
  display: none;
}

table {
  gap: 0;
  margin-block: -4px;
  overflow-x: auto;
}

table > thead, table > tbody, table > tfoot {
  gap: 0;
}

table > thead > tr, table > tbody > tr, table > tfoot > tr {
  flex-flow: row;
  padding-inline-start: max(4px, var(--margin-inline-start));
  padding-inline-end: max(4px, var(--margin-inline-end));
}

table > thead > tr > *, table > tbody > tr > *, table > tfoot > tr > * {
  --margin-inline-start: 0;
  --margin-inline-end: 0;
  flex: 1 0 0;
  min-width: 0;
  padding-block: 4px;
  display: block;
}

table > thead > tr > * *, table > tbody > tr > * *, table > tfoot > tr > * * {
  display: inline;
}

table > thead > tr[data-done], table > tbody > tr[data-done], table > tfoot > tr[data-done] {
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
  text-decoration: line-through;
}

table > thead > tr[data-active], table > tbody > tr[data-active], table > tfoot > tr[data-active] {
  background-color: var(--md-sys-color-primary);
  color: var(--md-sys-color-on-primary);
}

h1, h2, h3, h4, h5, h6, p {
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  display: block;
}

h1 > *, h2 > *, h3 > *, h4 > *, h5 > *, h6 > *, p > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

h1 *, h2 *, h3 *, h4 *, h5 *, h6 *, p * {
  display: inline;
}

ul, ol {
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-left: 2.5rem;
}

ul > *, ol > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

li {
  display: list-item;
}

dl {
  flex-flow: row;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
}

dl > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

dl dt {
  opacity: .5;
  text-align: right;
  flex: none;
  width: 100px;
}

dl dd {
  min-width: 0;
}

dt, dd {
  display: block;
}

dt *, dd * {
  display: inline;
}

[role="toolbar"] {
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
}

[role="toolbar"] > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

hgroup {
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
}

hgroup > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

hgroup h1, hgroup h2, hgroup h3, hgroup h4, hgroup h5, hgroup h6 {
  flex: 1;
}

hgroup [role="toolbar"] {
  margin-inline-end: calc(var(--margin-inline-end)  - 8px);
}

hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])) {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-on-surface-variant);
  background-color: #0000;
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 8px;
  text-decoration: none;
}

hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])) > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])) strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"]))[disabled], hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"]))[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])):where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])):where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"]))[disabled], hgroup [role="toolbar"] :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"]))[aria-disabled="true"] {
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

hgroup, [role="toolbar"], [role="group"] {
  flex-flow: row;
  align-items: center;
}

[role="group"] {
  background: var(--md-sys-color-outline);
  border-radius: 1000000px;
  flex: 0;
  justify-self: start;
  gap: 1px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding: 1px;
}

[role="group"] > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

[role="group"] > :not(:first-child) {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
}

[role="group"] > :not(:last-child) {
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])) {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-primary);
  outline-color: #0000;
  outline-color: var(--md-sys-color-outline);
  background-color: #0000;
  border: none;
  border-radius: 100000px;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 24px;
  text-decoration: none;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])) > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])) strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"]))[disabled], :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"]))[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])):where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])):where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"]))[disabled], :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"]))[aria-disabled="true"] {
  outline-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

:where(:not([role]):is(input[type="submit"], button[type="submit"])) {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-on-primary);
  background-color: #0000;
  background-color: var(--md-sys-color-primary);
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 24px;
  text-decoration: none;
}

:where(:not([role]):is(input[type="submit"], button[type="submit"])) > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

:where(:not([role]):is(input[type="submit"], button[type="submit"])) strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

:where(:not([role]):is(input[type="submit"], button[type="submit"]))[disabled], :where(:not([role]):is(input[type="submit"], button[type="submit"]))[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

:where(:not([role]):is(input[type="submit"], button[type="submit"])):where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

:where(:not([role]):is(input[type="submit"], button[type="submit"])):where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

:where(:not([role]):is(input[type="submit"], button[type="submit"]))[disabled], :where(:not([role]):is(input[type="submit"], button[type="submit"]))[aria-disabled="true"] {
  background-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-on-primary);
  background-color: #0000;
  background-color: var(--md-sys-color-primary);
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 24px;
  text-decoration: none;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary[disabled], :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary:where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary:where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary[disabled], :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).primary[aria-disabled="true"] {
  background-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-on-secondary-container);
  background-color: #0000;
  background-color: var(--md-sys-color-secondary-container);
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 24px;
  text-decoration: none;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary[disabled], :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary:where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary:where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary[disabled], :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).secondary[aria-disabled="true"] {
  background-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-primary);
  background-color: #0000;
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 12px;
  text-decoration: none;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio[disabled], :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio:where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio:where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio[disabled], :where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])).low-prio[aria-disabled="true"] {
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

label:not([role]) {
  flex-flow: row;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  position: relative;
}

label:not([role]) > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

label:not([role]) > span {
  color: var(--md-sys-color-on-surface-variant);
  position: absolute;
  inset-block-start: 8px;
  inset-inline: 16px;
}

label:not([role]) > :not(span) {
  resize: none;
  font-family: var(--md-sys-typescale-body-medium--font);
  font-size: var(--md-sys-typescale-body-medium--size);
  font-weight: var(--md-sys-typescale-body-medium--weight);
  line-height: var(--md-sys-typescale-body-medium--line-height);
  letter-spacing: var(--md-sys-typescale-body-medium--tracking);
  background-color: var(--md-sys-color-surface-container-highest);
  color: var(--md-sys-color-on-surface);
  border: 0;
  border-block-end: 1px solid var(--md-sys-color-on-surface-variant);
  outline: none;
  padding-block: 8px;
  padding-inline: 16px;
}

label:not([role]) > :not(span):not(textarea):not(input[type="radio"]):not(input[type="checkbox"]) {
  height: 40px;
}

label:not([role]) > :not(span):not(input[type="radio"]):not(input[type="checkbox"]) {
  flex: auto;
}

label:not([role]) > :not(span) strong {
  font-weight: var(--md-sys-typescale-body-medium--weight-prominent);
}

label:not([role]):has(span) > :not(span) {
  padding-block-start: 24px;
}

label:not([role]):has(span) > :not(span):not(textarea):not(input[type="radio"]):not(input[type="checkbox"]) {
  height: 56px;
}

label:not([role]).high-label > :not(span) {
  padding-block-start: 40px;
}

label:not([role]).high-label > :not(span):not(textarea):not(input[type="radio"]):not(input[type="checkbox"]) {
  height: 72px;
}

label:not([role]):focus-within > span {
  color: var(--md-sys-color-primary);
}

label:not([role]):focus-within > :not(span) {
  border-block-end: 2px solid var(--md-sys-color-primary);
  padding-block-end: 7px;
}

label:not([role]) > :not(span) {
  background-color: #fff6;
}

label[role="button"] input[type="file"] {
  display: none !important;
}

hr:where(:not([data-total])) {
  background-color: var(--md-sys-color-outline-variant);
  background-image: var(--gradient);
  border: 0 #0000;
  flex: none;
  height: 1px;
  margin-block: 8px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
}

hr:where(:not([data-total])) > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

hr:where([data-total]) {
  background-color: var(--md-sys-color-outline-variant);
  background-image: var(--gradient);
  border: 0 #0000;
  flex: none;
  height: 1px;
  margin-block: 8px;
}

svg {
  fill: currentColor;
}

dialog.fullscreen {
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
}

dialog.fullscreen > * {
  flex: 1;
  margin: 0;
}

dialog.fullscreen:not([open]) {
  visibility: hidden;
}

dialog.basic {
  background-color: var(--md-sys-color-surface-container-low);
  color: var(--md-sys-color-on-surface);
  --gradient: var(--gradient-center);
  background-color: var(--md-sys-color-surface-container-high);
  background: var(--md-sys-color-surface-container-high);
  opacity: 1;
  z-index: 10;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 16px;
  border-radius: 28px;
  outline-color: #0000;
  min-width: 280px;
  max-width: min(100vw - 64px, 560px);
  max-height: calc(100vh - 32px);
  margin: auto;
  padding: 0;
  transition-property: visibility, opacity, transform, max-height, box-shadow;
  transition-duration: .6s;
  transition-timing-function: cubic-bezier(.05, .7, .1, 1);
  overflow: visible;
  box-shadow: 0 0 4px #dfd6f5;
}

dialog.basic > * {
  --margin-inline-start: 16px;
  --margin-inline-end: 16px;
}

dialog.basic:after {
  content: " ";
  opacity: 1;
  z-index: -1;
  border-radius: 28px;
  transition-property: opacity;
  transition-duration: .6s;
  transition-timing-function: cubic-bezier(.05, .7, .1, 1);
  position: absolute;
  inset: 0;
  box-shadow: 0 0 0 calc(100vw + 100vh) #00000052;
}

dialog.basic > form {
  opacity: 1;
  transition-property: opacity;
  transition-duration: .6s;
  transition-timing-function: cubic-bezier(.05, .7, .1, 1);
  overflow: hidden;
}

dialog.basic > form > :first-child {
  margin-block-start: 24px;
}

dialog.basic > form > :not(:first-child):not(:last-child) {
  overflow: hidden auto;
}

dialog.basic > form > :last-child {
  margin-block-end: 24px;
}

dialog.basic > form > * {
  --margin-inline-start: 24px;
  --margin-inline-end: 24px;
}

dialog.basic h4 {
  text-align: center;
  max-width: calc(100% - var(--margin-inline-start)  - var(--margin-inline-end));
  width: 100vw;
}

dialog.basic button {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-primary);
  background-color: #0000;
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 12px;
  text-decoration: none;
}

dialog.basic button > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

dialog.basic button strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

dialog.basic button[disabled], dialog.basic button[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

dialog.basic button:where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

dialog.basic button:where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

dialog.basic button[disabled], dialog.basic button[aria-disabled="true"] {
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

dialog.basic form {
  gap: 16px;
}

dialog.basic .row {
  flex-flow: row-reverse wrap;
}

dialog.basic:not([open]) {
  visibility: hidden;
  transform-origin: center;
  max-height: 0;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.3, 0, .8, .15);
}

dialog.basic:not([open]):after, dialog.basic:not([open]) > form {
  opacity: 0;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.3, 0, .8, .15);
}

::backdrop {
  opacity: 0;
}

.StripeElement {
  flex: none;
  height: max-content;
  padding-inline-start: calc(var(--margin-inline-start)  - 16px);
  padding-inline-end: calc(var(--margin-inline-end)  - 16px);
  overflow: hidden;
}

@media (width >= 600px) {
  .only-on-compact {
    display: none !important;
  }
}

@media (width >= 840px) {
  .only-on-medium-minus {
    display: none !important;
  }
}

@media (width <= 599px) {
  .only-on-medium-plus {
    display: none !important;
  }
}

@media (width <= 839px) {
  .only-on-expanded-plus {
    display: none !important;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.tf-v1-popup {
  z-index: 10001;
  background: #000000bf;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .25s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.tf-v1-popup .tf-v1-iframe-wrapper {
  min-width: 360px;
  min-height: 360px;
  transition: opacity .25s ease-in-out;
  position: relative;
}

.tf-v1-popup .tf-v1-iframe-wrapper iframe {
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tf-v1-popup .tf-v1-close {
  text-align: center;
  text-transform: none;
  cursor: pointer;
  opacity: .75;
  color: #000;
  background: none;
  border: none;
  border-radius: 0;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: normal;
  line-height: 24px;
  text-decoration: none;
  transition: opacity .25s ease-in-out;
  display: block;
  position: absolute;
  top: -34px;
  right: 0;
}

.tf-v1-popup .tf-v1-close:hover {
  opacity: 1;
}

@media (width >= 481px) {
  .tf-v1-popup .tf-v1-close {
    color: #fff !important;
  }
}

.tf-v1-popup .tf-v1-spinner {
  box-sizing: border-box;
  border: 3px solid #aaa;
  border-top-color: #fff;
  border-radius: .5em;
  width: 1em;
  height: 1em;
  margin: -20px 0 0 -20px;
  font-size: 40px;
  animation: 1s linear infinite spin;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (width <= 480px) {
  .tf-v1-popup {
    width: 100% !important;
    height: 100% !important;
  }

  .tf-v1-popup .tf-v1-iframe-wrapper {
    min-width: 100%;
    min-height: 100%;
    transition: opacity .25s ease-in-out;
    position: relative;
  }

  .tf-v1-popup .tf-v1-iframe-wrapper iframe {
    border-radius: 0;
  }

  .tf-v1-popup .tf-v1-close {
    text-align: center;
    text-transform: none;
    cursor: pointer;
    opacity: .75;
    color: #000;
    background: none;
    border: none;
    border-radius: 0;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: normal;
    line-height: 24px;
    text-decoration: none;
    transition: opacity .25s ease-in-out;
    display: block;
    position: absolute;
    top: 6px;
    right: 8px;
  }

  .tf-v1-popup .tf-v1-close:hover {
    opacity: 1;
  }
}

@media (width <= 480px) and (width >= 481px) {
  .tf-v1-popup .tf-v1-close {
    color: #fff !important;
  }
}

@font-face {
  font-family: Material Symbols Outlined;
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url("MaterialSymbolsOutlined[FILL,GRAD,opsz,wght]-subset.d83bd720.woff2") format("woff2");
}

.material-symbols-outlined {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-family: Material Symbols Outlined;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

body {
  position: relative;
}

.material-symbols-outlined {
  font-weight: inherit;
  font-variation-settings: "FILL" 0, "GRAD" 0, "opsz" 24;
}

#app {
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  flex-flow: column-reverse;
  gap: 0;
  margin: 0 auto;
  position: absolute;
  inset: 0;
}

#app > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

@media (width >= 600px) {
  #app {
    flex-flow: row;
    justify-content: center;
  }
}

#app > nav[role="tablist"] {
  flex-flow: row;
}

@media (width >= 600px) {
  #app > nav[role="tablist"] {
    flex-flow: column;
    justify-content: start;
    width: 80px;
    padding-block: 16px;
  }
}

:root.light-only #app > nav[role="tablist"] {
  backdrop-filter: blur(.3rem);
  background-color: #f1ebff80;
  box-shadow: 0 0 .5rem #0000001a;
}

@media not (prefers-color-scheme: dark) {
  #app > nav[role="tablist"] {
    backdrop-filter: blur(.3rem);
    background-color: #f1ebff80;
    box-shadow: 0 0 .5rem #0000001a;
  }
}

#app > nav[role="tablist"] > [role="tab"] {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-on-surface-variant);
  font-family: var(--md-sys-typescale-label-medium--font);
  font-size: var(--md-sys-typescale-label-medium--size);
  font-weight: var(--md-sys-typescale-label-medium--weight);
  line-height: var(--md-sys-typescale-label-medium--line-height);
  letter-spacing: var(--md-sys-typescale-label-medium--tracking);
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  background-color: #0000;
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: column;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: auto;
  margin: 0;
  padding-block: 12px 16px;
  padding-inline: 0;
  text-decoration: none;
  display: flex;
}

#app > nav[role="tablist"] > [role="tab"] > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

#app > nav[role="tablist"] > [role="tab"][disabled], #app > nav[role="tablist"] > [role="tab"][aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

#app > nav[role="tablist"] > [role="tab"]:where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

#app > nav[role="tablist"] > [role="tab"]:where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

#app > nav[role="tablist"] > [role="tab"][disabled], #app > nav[role="tablist"] > [role="tab"][aria-disabled="true"] {
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

@media (width >= 600px) {
  #app > nav[role="tablist"] > [role="tab"] {
    flex: none;
    padding-block: 0;
  }
}

#app > nav[role="tablist"] > [role="tab"] strong {
  font-weight: var(--md-sys-typescale-label-medium--weight-prominent);
}

#app > nav[role="tablist"] > [role="tab"] span.material-symbols-outlined {
  box-sizing: content-box;
  border-radius: 16px;
  padding-block: 4px;
  padding-inline: 20px;
  transition-property: color, font-variation-settings;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.2, 0, 0, 1);
}

#app > nav[role="tablist"] > [role="tab"][aria-selected] {
  color: var(--md-sys-color-on-surface);
}

#app > nav[role="tablist"] > [role="tab"][aria-selected] span.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "GRAD" 0, "opsz" 24;
  background-color: var(--md-sys-color-secondary-container);
}

#app > div {
  --viewport-width: 100vw;
  flex: 1;
  overflow: hidden;
  box-shadow: 0 0 .5rem #0000001a;
}

@media (width >= 600px) {
  #app > div {
    --viewport-width: calc(100vw - 80px);
  }
}

#app > div > [role="tabpanel"] {
  flex-flow: row;
  flex: 1;
  transition-property: margin-inline-start;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.3, 0, .8, .15);
  overflow: hidden;
}

#app > div > [role="tabpanel"][data-subpage-open] {
  margin-inline-start: calc(0px - var(--viewport-width)  - 8px);
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.05, .7, .1, 1);
}

#app > div > [role="tabpanel"] > * {
  min-width: var(--viewport-width);
  overflow-y: scroll;
}

#app > .sign-in, #app > .register {
  justify-content: center;
}

@media (width >= 600px) {
  #app > .sign-in > main, #app > .register > main {
    width: 400px;
    margin: 0 auto;
  }
}

p:not(.display-large) {
  max-width: 600px;
}

@media (width >= 840px) {
  main > section, main > div > section {
    width: 600px;
    margin-inline: auto;
  }

  main > section > *, main > div > section > * {
    --margin-inline-start: 0px;
    --margin-inline-end: 0px;
  }

  main > section > h1, main > section > h2, main > div > section > h1, main > div > section > h2 {
    margin: -6px;
    padding: 6px;
  }
}

@media (width >= 1200px) {
  main > section, main > div > section {
    width: auto;
    padding-block: 32px;
  }

  main > section, main > section > form, main > section > div, main > div > section, main > div > section > form, main > div > section > div {
    gap: 16px;
  }

  main > section:not([data-done]), main > div > section:not([data-done]) {
    grid-template-rows: auto;
    grid-template-columns: 260px 600px;
    column-gap: 60px;
    display: grid;
  }

  main > section:not([data-done]) > hgroup, main > section:not([data-done]) > h1, main > section:not([data-done]) > h2, main > div > section:not([data-done]) > hgroup, main > div > section:not([data-done]) > h1, main > div > section:not([data-done]) > h2 {
    box-sizing: content-box;
    text-align: end;
    margin-inline: 0;
    flex-flow: column;
    grid-column: 1 / 1;
    align-items: end;
    width: 260px;
    margin: -20px;
    padding: 20px;
    position: absolute;
  }

  main > section:not([data-done]) > h1, main > div > section:not([data-done]) > h1 {
    background-color: var(--md-sys-color-primary);
    color: var(--md-sys-color-on-primary);
    background-clip: padding-box;
  }

  main > section:not([data-done]) > *, main > div > section:not([data-done]) > * {
    grid-column: 2;
  }
}

.calendar {
  -webkit-user-select: none;
  user-select: none;
}

.calendar tr {
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-inline: 0;
}

.calendar tr > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

@media (width <= 339px) {
  .calendar tr {
    margin-inline: auto;
  }
}

@media (width <= 359px) {
  .calendar tr {
    column-gap: 4px;
  }
}

.calendar th, .calendar td {
  text-align: center;
  width: 40px;
  overflow: hidden;
}

.calendar th span, .calendar td button {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  display: inline-flex;
}

.calendar td button {
  outline-color: #0000;
}

.calendar td button:not([disabled]) {
  background-color: var(--md-extended-color-pink-color-container);
  color: var(--md-extended-color-pink-on-color-container);
}

.calendar td[data-today] button {
  outline-color: var(--md-extended-color-pink-color);
}

.calendar td[data-active] button {
  background-color: var(--md-extended-color-pink-color);
  color: var(--md-extended-color-pink-on-color);
}

.calendar td[data-done] button {
  text-decoration: line-through;
}

.display-large {
  font-family: var(--md-sys-typescale-display-medium--font);
  font-size: var(--md-sys-typescale-display-medium--size);
  font-weight: var(--md-sys-typescale-display-medium--weight);
  line-height: var(--md-sys-typescale-display-medium--line-height);
  letter-spacing: var(--md-sys-typescale-display-medium--tracking);
  color: var(--md-sys-color-primary);
}

.display-large strong {
  font-weight: var(--md-sys-typescale-display-medium--weight-prominent);
}

.display-medium {
  font-family: var(--md-sys-typescale-display-small--font);
  font-size: var(--md-sys-typescale-display-small--size);
  font-weight: var(--md-sys-typescale-display-small--weight);
  line-height: var(--md-sys-typescale-display-small--line-height);
  letter-spacing: var(--md-sys-typescale-display-small--tracking);
}

.display-medium strong {
  font-weight: var(--md-sys-typescale-display-small--weight-prominent);
}

.display-small {
  font-family: var(--md-sys-typescale-headline-medium--font);
  font-size: var(--md-sys-typescale-headline-medium--size);
  font-weight: var(--md-sys-typescale-headline-medium--weight);
  line-height: var(--md-sys-typescale-headline-medium--line-height);
  letter-spacing: var(--md-sys-typescale-headline-medium--tracking);
}

.display-small strong {
  font-weight: var(--md-sys-typescale-headline-medium--weight-prominent);
}

.body-medium {
  font-family: var(--md-sys-typescale-body-medium--font);
  font-size: var(--md-sys-typescale-body-medium--size);
  font-weight: var(--md-sys-typescale-body-medium--weight);
  line-height: var(--md-sys-typescale-body-medium--line-height);
  letter-spacing: var(--md-sys-typescale-body-medium--tracking);
}

.body-medium strong {
  font-weight: var(--md-sys-typescale-body-medium--weight-prominent);
}

.body-small {
  font-family: var(--md-sys-typescale-body-small--font);
  font-size: var(--md-sys-typescale-body-small--size);
  font-weight: var(--md-sys-typescale-body-small--weight);
  line-height: var(--md-sys-typescale-body-small--line-height);
  letter-spacing: var(--md-sys-typescale-body-small--tracking);
}

.body-small strong {
  font-weight: var(--md-sys-typescale-body-small--weight-prominent);
}

.label-medium {
  font-family: var(--md-sys-typescale-label-medium--font);
  font-size: var(--md-sys-typescale-label-medium--size);
  font-weight: var(--md-sys-typescale-label-medium--weight);
  line-height: var(--md-sys-typescale-label-medium--line-height);
  letter-spacing: var(--md-sys-typescale-label-medium--tracking);
}

.label-medium strong {
  font-weight: var(--md-sys-typescale-label-medium--weight-prominent);
}

.label-small {
  font-family: var(--md-sys-typescale-label-small--font);
  font-size: var(--md-sys-typescale-label-small--size);
  font-weight: var(--md-sys-typescale-label-small--weight);
  line-height: var(--md-sys-typescale-label-small--line-height);
  letter-spacing: var(--md-sys-typescale-label-small--tracking);
}

.label-small strong {
  font-weight: var(--md-sys-typescale-label-small--weight-prominent);
}

@media (width >= 600px) {
  .display-large {
    font-family: var(--md-sys-typescale-display-large--font);
    font-size: var(--md-sys-typescale-display-large--size);
    font-weight: var(--md-sys-typescale-display-large--weight);
    line-height: var(--md-sys-typescale-display-large--line-height);
    letter-spacing: var(--md-sys-typescale-display-large--tracking);
  }

  .display-large strong {
    font-weight: var(--md-sys-typescale-display-large--weight-prominent);
  }

  .display-medium {
    font-family: var(--md-sys-typescale-display-medium--font);
    font-size: var(--md-sys-typescale-display-medium--size);
    font-weight: var(--md-sys-typescale-display-medium--weight);
    line-height: var(--md-sys-typescale-display-medium--line-height);
    letter-spacing: var(--md-sys-typescale-display-medium--tracking);
  }

  .display-medium strong {
    font-weight: var(--md-sys-typescale-display-medium--weight-prominent);
  }

  .display-small {
    font-family: var(--md-sys-typescale-display-small--font);
    font-size: var(--md-sys-typescale-display-small--size);
    font-weight: var(--md-sys-typescale-display-small--weight);
    line-height: var(--md-sys-typescale-display-small--line-height);
    letter-spacing: var(--md-sys-typescale-display-small--tracking);
  }

  .display-small strong {
    font-weight: var(--md-sys-typescale-display-small--weight-prominent);
  }
}

main {
  border-image: var(--gradient) 1;
  border-width: 4px 0;
  margin-inline: 0;
  padding-block: 16px;
}

@media (width >= 600px) {
  main {
    margin-inline-start: var(--margin-inline-start);
    margin-inline-end: var(--margin-inline-end);
  }
}

main > * {
  --margin-inline-start: 16px;
  --margin-inline-end: 16px;
}

:root.light-only main {
  backdrop-filter: blur(.3rem);
  background-color: #f1ebff80;
  box-shadow: 0 0 .5rem #0000001a;
}

@media not (prefers-color-scheme: dark) {
  main {
    backdrop-filter: blur(.3rem);
    background-color: #f1ebff80;
    box-shadow: 0 0 .5rem #0000001a;
  }
}

@media (width >= 600px) {
  main {
    border-width: 4px;
  }
}

article {
  background-color: var(--md-sys-color-surface-container-low);
  color: var(--md-sys-color-on-surface);
  --gradient: var(--gradient-center);
  border-radius: 4px;
  outline-color: #0000;
  margin-block: 8px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 16px;
  box-shadow: 0 0 4px #dfd6f5;
}

article > * {
  --margin-inline-start: 16px;
  --margin-inline-end: 16px;
}

fieldset {
  color: var(--md-sys-color-on-surface);
  outline-color: var(--md-sys-color-outline-variant);
  box-shadow: none;
  --gradient: var(--gradient-center);
  background-color: #0000;
  border-radius: 4px;
  margin-block: 8px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 16px;
}

fieldset > * {
  --margin-inline-start: 16px;
  --margin-inline-end: 16px;
}

article > hgroup, fieldset > hgroup {
  height: 40px;
  margin-block: calc(.875rem - 20px);
}

.radio-pool {
  flex-flow: wrap;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
}

.radio-pool > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

.radio-pool button {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-on-primary);
  background-color: #0000;
  background-color: var(--md-sys-color-primary);
  background-color: var(--md-extended-color-blue-color);
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 24px;
  text-decoration: none;
}

.radio-pool button > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

.radio-pool button strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

.radio-pool button[disabled], .radio-pool button[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

.radio-pool button:where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

.radio-pool button:where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

.radio-pool button[disabled], .radio-pool button[aria-disabled="true"] {
  background-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

aside {
  color: var(--md-sys-color-on-surface-variant);
  align-items: center;
}

.row {
  flex-flow: wrap;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
}

.row > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

ul.files {
  margin-bottom: 2px;
  padding-inline-start: 0;
}

ul.files li {
  background-color: var(--md-sys-color-surface-container-low);
  color: var(--md-sys-color-on-surface);
  --gradient: var(--gradient-center);
  border-radius: 4px;
  outline-color: #0000;
  flex-flow: row;
  align-items: center;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  display: flex;
  box-shadow: 0 0 4px #dfd6f5;
}

ul.files li > * {
  --margin-inline-start: 16px;
  --margin-inline-end: 16px;
}

ul.files li span {
  margin-inline: var(--margin-inline-start);
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  display: block;
  overflow: hidden;
}

ul.files li button {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-primary);
  --margin-inline-start: 0;
  --margin-inline-end: 0;
  background-color: #0000;
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 12px;
  text-decoration: none;
}

ul.files li button > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

ul.files li button strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

ul.files li button[disabled], ul.files li button[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

ul.files li button:where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

ul.files li button:where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

ul.files li button[disabled], ul.files li button[aria-disabled="true"] {
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

.dev {
  background-color: var(--md-sys-color-error-container);
  color: var(--md-sys-color-on-error-container);
  border-radius: 4px;
  padding: 8px;
}

aside.dev {
  align-self: center;
  align-items: stretch;
  width: 100%;
  padding-block: 16px;
}

@media (width >= 600px) {
  aside.dev {
    width: 400px;
    padding-block: 32px;
  }
}

[data-done] {
  color: var(--md-sys-color-on-surface-variant);
  text-decoration: line-through;
}

[data-done] h2:before {
  content: "✓";
  display: inline-block;
}

ul.steps li {
  list-style-type: "☐ ";
}

.steps li[data-done] {
  list-style-type: "☑ ";
}

article ul {
  padding-left: 0;
}

article ul li {
  list-style-type: none;
}

.runout {
  background-color: var(--md-sys-color-surface-container-highest);
  color: var(--md-sys-color-on-error);
  box-shadow: none;
  --gradient: var(--gradient-center);
  background-color: var(--md-sys-color-error);
  border-radius: 4px;
  outline-color: #0000;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 16px;
}

.runout > * {
  --margin-inline-start: 16px;
  --margin-inline-end: 16px;
}

.runout p {
  text-align: center;
}

.runout button {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  cursor: default;
  color: var(--md-sys-color-on-primary-container);
  background-color: #0000;
  background-color: var(--md-sys-color-secondary-container);
  background-color: #f6f1ff;
  border: none;
  border-radius: 100000px;
  outline-color: #0000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-inline-start: var(--margin-inline-start);
  margin-inline-end: var(--margin-inline-end);
  padding-block: 0;
  padding-inline: 24px;
  text-decoration: none;
}

.runout button > * {
  --margin-inline-start: 0px;
  --margin-inline-end: 0px;
}

.runout button strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

.runout button[disabled], .runout button[aria-disabled="true"] {
  background-color: #0000;
  outline-color: #0000;
}

.runout button:where(input[type="file"]) {
  line-height: 40px;
  display: block;
}

.runout button:where(input[type="file"])::file-selector-button {
  appearance: none;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-inline-end: .5rem;
}

.runout button[disabled], .runout button[aria-disabled="true"] {
  background-color: color-mix(in srgb, var(--md-sys-color-on-surface) 12%, transparent);
  color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent);
}

article .expander {
  justify-content: end;
  margin-block: -16px;
  margin-inline: 4px;
}

.hello > :first-child {
  flex: 1;
}

.tf-v1-close {
  outline: none;
}

#app > .app-install-banner {
  background: var(--md-sys-color-primary);
  flex: none;
  padding-inline: 16px;
}

#app > .app-install-banner p {
  font-family: var(--md-sys-typescale-label-large--font);
  font-size: var(--md-sys-typescale-label-large--size);
  font-weight: var(--md-sys-typescale-label-large--weight);
  line-height: var(--md-sys-typescale-label-large--line-height);
  letter-spacing: var(--md-sys-typescale-label-large--tracking);
  color: var(--md-sys-color-on-primary);
  flex: 1;
}

#app > .app-install-banner p strong {
  font-weight: var(--md-sys-typescale-label-large--weight-prominent);
}

#app > .app-install-banner button {
  color: var(--md-sys-color-on-primary);
}

@media (width >= 600px) {
  #app > .app-install-banner {
    display: none;
  }
}
/*# sourceMappingURL=main.778348b8.css.map */
