@use './util' as util;
@use './theme' as theme;

*, *::before, *::after {
	box-sizing: border-box;
}

* {
	margin: 0;
	padding: 0;
	border: 0px solid transparent;
	background-origin: border-box;
	background-repeat: no-repeat;
	font: inherit;
	overflow-wrap: inherit;
	gap: 8px;
}

html {
	overflow-wrap: break-word;
	background-color: var(--md-sys-color-background);
	color: var(--md-sys-color-on-background);
	--margin-inline-start: 16px;
	--margin-inline-end: 16px;
	@media (min-width: 600px) {
		--margin-inline-start: 24px;
		--margin-inline-end: 24px;
	}
	--spacer: 24px;
	height: 100%;
	display: flex;
	flex-flow: column;
}

body {
	@include util.apply-typescale('md-sys-typescale-label-medium');
}

h1, h2 {
	@include util.apply-typescale('md-sys-typescale-headline-medium');
}

h3 {
	@include util.apply-typescale('md-sys-typescale-headline-small');
}

h4 {
	@include util.apply-typescale('md-sys-typescale-title-large');
}

h5 {
	@include util.apply-typescale('md-sys-typescale-title-medium');
}

h6 {
	@include util.apply-typescale('md-sys-typescale-title-small');
}

th, td, p, li, dt, dd {
	@include util.apply-typescale('md-sys-typescale-body-medium');
}

aside p, small {
	@include util.apply-typescale('md-sys-typescale-body-small');
	color: var(--md-sys-color-on-surface-variant);
}

b {
	font-weight: bold;
}

i {
	font-style: italic;
}

a {
	color: var(--md-sys-color-primary);
}

body {
	flex: 1 1 0;
}

:where(body, body *) {
	display: flex;
	flex-flow: column;
	outline: 1px solid transparent;
	outline-offset: -1px;
}

[hidden] {
	display: none;
}

main nav {
	@include theme.top-app-bar-center;
}

p {
	text-align: justify;
}

script, style {
	display: none;
}

@mixin text {
	display: block;

	* {
		display: inline;
	}
}

table {
	overflow-x: auto;
	gap: 0;
	margin-block: -4px;

	> thead, > tbody, > tfoot {
		gap: 0;

		> tr {
			flex-flow: row;
			padding-inline-start: max(4px, var(--margin-inline-start));
			padding-inline-end: max(4px, var(--margin-inline-end));

			> * {
				--margin-inline-start: 0;
				--margin-inline-end: 0;

				flex: 1 0 0;
				min-width: 0;
				padding-block: 4px;
				@include text;
			}

			&[data-done] {
				text-decoration: line-through;
				color: color-mix(in srgb, var(--md-sys-color-on-surface) 38%, transparent); // as in disabled
			}

			&[data-active] {
				background-color: var(--md-sys-color-primary);
				color: var(--md-sys-color-on-primary);
			}
		}
	}
}

h1, h2, h3, h4, h5, h6, p {
	@include util.block;
	@include text;
}

ul, ol {
	@include util.block;
	padding-left: 2.5rem;
}

li {
	display: list-item;
}

dl {
	@include util.block;
	flex-flow: row;

	dt {
		opacity: 0.5;
		text-align: right;
		width: 100px;
		flex: 0 0 auto;
	}

	dd {
		min-width: 0;
	}
}

dt, dd {
	@include text;
}

[role="toolbar"] {
	@include util.block;
}

hgroup {
	@include util.block;
	h1, h2, h3, h4, h5, h6 {
		flex: 1;
	}
	[role="toolbar"] {
		margin-inline-end: calc(var(--margin-inline-end) - 8px);

		:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])) {
			@include theme.button-text;
			color: var(--md-sys-color-on-surface-variant);
			padding-inline: 8px;
		}
	}
}

hgroup, [role="toolbar"], [role="group"] {
	flex-flow: row;
	align-items: center;
}

[role="group"] {
	@include util.block;
	gap: 1px;
	background: var(--md-sys-color-outline);
	padding: 1px;
	border-radius: 1000000px;
	flex: 0;
	justify-self: start;

	> *:not(:first-child) {
		border-start-start-radius: 0 !important;
		border-end-start-radius: 0 !important;
	}
	> *:not(:last-child) {
		border-start-end-radius: 0 !important;
		border-end-end-radius: 0 !important;
	}
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"])) {
	@include theme.button-outlined;
}

:where(:not([role]):is(input[type="submit"], button[type="submit"])) {
	@include theme.button-filled;
}

:where([role="button"], :not([role]):is(button, input[type="button"], input[type="file"], input[type="submit"], button[type="submit"])) {
	&.primary {
		@include theme.button-filled;
	}
	&.secondary {
		@include theme.button-filled-tonal;
	}
	&.low-prio {
		@include theme.button-text;
	}
}

label:not([role]) {
	@include theme.text-field-filled;
}

label[role="button"] input[type="file"] {
	display: none !important;
}

hr:where(:not([data-total])) {
	@include theme.divider-inset;
}

hr:where([data-total]) {
	@include theme.divider-full-width;
}

svg {
	fill: currentColor;
}

dialog.fullscreen {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;

	> * {
		margin: 0;
		flex: 1;
	}
}

dialog.fullscreen:not([open]) {
	visibility: hidden;
}

dialog.basic {
	@include theme.card-elevated;
	background-color: var(--md-sys-color-surface-container-high);
	padding: 0;
	margin: auto;
	border-radius: 28px;
	min-width: 280px;
	max-width: min(100vw - 64px, 560px);
	max-height: calc(100vh - 32px);
	overflow: visible;
	background: var(--md-sys-color-surface-container-high);
	transition-property: visibility, opacity, transform, max-height, box-shadow;
	transition-duration: 0.6s;
	transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1.0);
	opacity: 1;
	z-index: 10;
	&::after {
		content: " ";
		border-radius: 28px;
		box-shadow: 0 0 0 calc(100vw + 100vh) rgb(0 0 0 / .32);
		transition-property: opacity;
		transition-duration: 0.6s;
		transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1.0);
		opacity: 1;
		position: absolute;
		inset: 0;
		z-index: -1;
	}
	> form {
		overflow: hidden;
		opacity: 1;
		transition-property: opacity;
		transition-duration: 0.6s;
		transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1.0);
		> :first-child {
			margin-block-start: 24px;
		}
		> :not(:first-child):not(:last-child) {
			overflow-x: hidden;
			overflow-y: auto;
		}
		> :last-child {
			margin-block-end: 24px;
		}
		> * {
			--margin-inline-start: 24px;
			--margin-inline-end: 24px;
		}
	}

	h4 {
		text-align: center;
		width: 100vw;
		max-width: calc(100% - var(--margin-inline-start) - var(--margin-inline-end));
	}

	button {
		@include theme.button-text;
	}

	form {
		gap: 16px;
	}

	.row {
		flex-flow: row-reverse wrap;
	}
}

dialog.basic:not([open]) {
	visibility: hidden;
	transform-origin: center;
	max-height: 0;
	transition-timing-function: cubic-bezier(0.3, 0.0, 0.8, 0.15);
	transition-duration: 0.2s;
	&::after {
		opacity: 0;
		transition-timing-function: cubic-bezier(0.3, 0.0, 0.8, 0.15);
		transition-duration: 0.2s;
	}
	> form {
		opacity: 0;
		transition-timing-function: cubic-bezier(0.3, 0.0, 0.8, 0.15);
		transition-duration: 0.2s;
	}
}

::backdrop {
	opacity: 0%;
}

.StripeElement {
	padding-inline-start: calc(var(--margin-inline-start) - 16px);
	padding-inline-end: calc(var(--margin-inline-end) - 16px);
	overflow: hidden;
	height: max-content;
	flex: 0 0 auto;
}

.only-on-compact {
	@media (min-width: 600px) {
		display: none !important;
	}
}

.only-on-medium-minus {
	@media (min-width: 840px) {
		display: none !important;
	}
}

.only-on-medium-plus {
	@media (max-width: 599px) {
		display: none !important;
	}
}

.only-on-expanded-plus {
	@media (max-width: 839px) {
		display: none !important;
	}
}
