
:root {
	--md-ref-typeface-brand: 'Ubuntu', sans-serif;
	--md-ref-typeface-plain: 'Ubuntu', sans-serif;

	color-scheme: only light;

	// #56408B
	--md-sys-color-primary: rgb(102 85 143);
	--md-sys-color-surface-tint: rgb(102 85 143);
	--md-sys-color-on-primary: rgb(255 255 255);
	--md-sys-color-primary-container: rgb(233 221 255);
	--md-sys-color-on-primary-container: rgb(33 15 71);
	--md-sys-color-secondary: rgb(98 91 112);
	--md-sys-color-on-secondary: rgb(255 255 255);
	--md-sys-color-secondary-container: rgb(232 222 248);
	--md-sys-color-on-secondary-container: rgb(30 25 43);
	--md-sys-color-tertiary: rgb(126 82 95);
	--md-sys-color-on-tertiary: rgb(255 255 255);
	--md-sys-color-tertiary-container: rgb(255 217 226);
	--md-sys-color-on-tertiary-container: rgb(49 16 29);
	--md-sys-color-error: rgb(186 26 26);
	--md-sys-color-on-error: rgb(255 255 255);
	--md-sys-color-error-container: rgb(255 218 214);
	--md-sys-color-on-error-container: rgb(65 0 2);
	--md-sys-color-background: rgb(254 247 255);
	--md-sys-color-on-background: rgb(29 27 32);
	--md-sys-color-surface: rgb(254 247 255);
	--md-sys-color-on-surface: rgb(29 27 32);
	--md-sys-color-surface-variant: rgb(231 224 235);
	--md-sys-color-on-surface-variant: rgb(73 69 78);
	--md-sys-color-outline: rgb(122 117 127);
	--md-sys-color-outline-variant: rgb(202 196 207);
	--md-sys-color-shadow: rgb(0 0 0);
	--md-sys-color-scrim: rgb(0 0 0);
	--md-sys-color-inverse-surface: rgb(50 47 53);
	--md-sys-color-inverse-on-surface: rgb(245 239 247);
	--md-sys-color-inverse-primary: rgb(208 188 254);
	--md-sys-color-primary-fixed: rgb(233 221 255);
	--md-sys-color-on-primary-fixed: rgb(33 15 71);
	--md-sys-color-primary-fixed-dim: rgb(208 188 254);
	--md-sys-color-on-primary-fixed-variant: rgb(77 61 117);
	--md-sys-color-secondary-fixed: rgb(232 222 248);
	--md-sys-color-on-secondary-fixed: rgb(30 25 43);
	--md-sys-color-secondary-fixed-dim: rgb(204 194 219);
	--md-sys-color-on-secondary-fixed-variant: rgb(74 68 88);
	--md-sys-color-tertiary-fixed: rgb(255 217 226);
	--md-sys-color-on-tertiary-fixed: rgb(49 16 29);
	--md-sys-color-tertiary-fixed-dim: rgb(239 184 199);
	--md-sys-color-on-tertiary-fixed-variant: rgb(99 59 72);
	--md-sys-color-surface-dim: rgb(222 216 224);
	--md-sys-color-surface-bright: rgb(254 247 255);
	--md-sys-color-surface-container-lowest: rgb(255 255 255);
	--md-sys-color-surface-container-low: rgb(248 242 250);
	--md-sys-color-surface-container: rgb(242 236 244);
	--md-sys-color-surface-container-high: rgb(236 230 238);
	--md-sys-color-surface-container-highest: rgb(230 224 233);
	--md-extended-color-pink-color: rgb(134 75 112);
	--md-extended-color-pink-on-color: rgb(255 255 255);
	--md-extended-color-pink-color-container: rgb(255 216 236);
	--md-extended-color-pink-on-color-container: rgb(55 7 42);
	--md-extended-color-blue-color: rgb(21 102 131);
	--md-extended-color-blue-on-color: rgb(255 255 255);
	--md-extended-color-blue-color-container: rgb(191 232 255);
	--md-extended-color-blue-on-color-container: rgb(0 31 43);

	// override autogenerated surface container colors with HCT 291 9 [100, 96, 94, 92, 90]
	--md-sys-color-surface-container-lowest: #ffffff;
	--md-sys-color-surface-container-low: #f6f1ff;
	--md-sys-color-surface-container: #f1ecf9;
	--md-sys-color-surface-container-high: #ebe6f3;
	--md-sys-color-surface-container-highest: #e5e0ed;

	scrollbar-color: #b8e2f9 rgba(246, 241, 255, .5);

	@media (prefers-color-scheme: dark) {
	&:not(.light-only) {
		color-scheme: only dark;

		--md-sys-color-primary: rgb(208 188 254);
		--md-sys-color-surface-tint: rgb(208 188 254);
		--md-sys-color-on-primary: rgb(54 38 93);
		--md-sys-color-primary-container: rgb(77 61 117);
		--md-sys-color-on-primary-container: rgb(233 221 255);
		--md-sys-color-secondary: rgb(204 194 219);
		--md-sys-color-on-secondary: rgb(51 45 65);
		--md-sys-color-secondary-container: rgb(74 68 88);
		--md-sys-color-on-secondary-container: rgb(232 222 248);
		--md-sys-color-tertiary: rgb(239 184 199);
		--md-sys-color-on-tertiary: rgb(74 37 49);
		--md-sys-color-tertiary-container: rgb(99 59 72);
		--md-sys-color-on-tertiary-container: rgb(255 217 226);
		--md-sys-color-error: rgb(255 180 171);
		--md-sys-color-on-error: rgb(105 0 5);
		--md-sys-color-error-container: rgb(147 0 10);
		--md-sys-color-on-error-container: rgb(255 218 214);
		--md-sys-color-background: rgb(20 18 24);
		--md-sys-color-on-background: rgb(230 224 233);
		--md-sys-color-surface: rgb(20 18 24);
		--md-sys-color-on-surface: rgb(230 224 233);
		--md-sys-color-surface-variant: rgb(73 69 78);
		--md-sys-color-on-surface-variant: rgb(202 196 207);
		--md-sys-color-outline: rgb(148 143 153);
		--md-sys-color-outline-variant: rgb(73 69 78);
		--md-sys-color-shadow: rgb(0 0 0);
		--md-sys-color-scrim: rgb(0 0 0);
		--md-sys-color-inverse-surface: rgb(230 224 233);
		--md-sys-color-inverse-on-surface: rgb(50 47 53);
		--md-sys-color-inverse-primary: rgb(102 85 143);
		--md-sys-color-primary-fixed: rgb(233 221 255);
		--md-sys-color-on-primary-fixed: rgb(33 15 71);
		--md-sys-color-primary-fixed-dim: rgb(208 188 254);
		--md-sys-color-on-primary-fixed-variant: rgb(77 61 117);
		--md-sys-color-secondary-fixed: rgb(232 222 248);
		--md-sys-color-on-secondary-fixed: rgb(30 25 43);
		--md-sys-color-secondary-fixed-dim: rgb(204 194 219);
		--md-sys-color-on-secondary-fixed-variant: rgb(74 68 88);
		--md-sys-color-tertiary-fixed: rgb(255 217 226);
		--md-sys-color-on-tertiary-fixed: rgb(49 16 29);
		--md-sys-color-tertiary-fixed-dim: rgb(239 184 199);
		--md-sys-color-on-tertiary-fixed-variant: rgb(99 59 72);
		--md-sys-color-surface-dim: rgb(20 18 24);
		--md-sys-color-surface-bright: rgb(59 56 62);
		--md-sys-color-surface-container-lowest: rgb(15 13 19);
		--md-sys-color-surface-container-low: rgb(29 27 32);
		--md-sys-color-surface-container: rgb(33 31 36);
		--md-sys-color-surface-container-high: rgb(43 41 47);
		--md-sys-color-surface-container-highest: rgb(54 52 58);
		--md-extended-color-pink-color: rgb(249 177 219);
		--md-extended-color-pink-on-color: rgb(80 30 64);
		--md-extended-color-pink-color-container: rgb(106 52 87);
		--md-extended-color-pink-on-color-container: rgb(255 216 236);
		--md-extended-color-blue-color: rgb(140 207 241);
		--md-extended-color-blue-on-color: rgb(0 53 71);
		--md-extended-color-blue-color-container: rgb(0 77 102);
		--md-extended-color-blue-on-color-container: rgb(191 232 255);

		scrollbar-color: #b8e2f9 rgba(20, 18, 24, .5);
	}
	}

	--gradient: linear-gradient(90deg, #fccbe5, #b8e2f9);
	--gradient-start: linear-gradient(90deg, #fccbe5, #dad7ef);
	--gradient-center: linear-gradient(90deg, #dad7ef, #dad7ef);
	--gradient-end: linear-gradient(90deg, #dad7ef, #b8e2f9);
}

#bg {
	display: none;

	@at-root :root.light-only & {
		position: absolute;
		inset: 0 0 auto 0;
		height: 100%;
		display: block;
		overflow: hidden;
		z-index: -1;
	}

	@media not (prefers-color-scheme: dark) {
		position: absolute;
		inset: 0 0 auto 0;
		height: 100%;
		display: block;
		overflow: hidden;
		z-index: -1;
	}
}
