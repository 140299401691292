@use "sass:meta";
@use 'mdsass' as util;
@use 'mdsass-theme-imago' as imago;
@use 'mdsass/theme' as theme with (
	$theme: meta.module-mixins('imago')
);
@use 'mdsass/html';
@use '@typeform/embed/build/css/popup.css';

@font-face {
	font-family: 'Material Symbols Outlined';
	font-style: normal;
	font-weight: 100 700;
	font-display: block;
	// built with https://github.com/rsheeter/subset-gf-icons:
	// subset_gf_icons MaterialSymbolsOutlined\[FILL\,GRAD\,opsz\,wght\].ttf keyboard_arrow_down keyboard_arrow_up home medical_services call calendar_month keyboard_arrow_left keyboard_arrow_right arrow_back manage_accounts delete install_mobile install_desktop surgical --flavor woff2
	src: url('./MaterialSymbolsOutlined[FILL,GRAD,opsz,wght]-subset.woff2') format('woff2');
}

.material-symbols-outlined {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

body {
	position: relative;
}

.material-symbols-outlined {
	font-weight: inherit;
	font-variation-settings: 'FILL' 0, 'GRAD' 0, 'opsz' 24;
}

#app {
	position: absolute;
	inset: 0;
	gap: 0;
	@include util.block;
	margin: 0 auto;

	flex-flow: column-reverse;
	@media (min-width: 600px) {
		flex-flow: row;
		justify-content: center;
	}

	> nav[role="tablist"] {
		flex-flow: row;
		@media (min-width: 600px) {
			flex-flow: column;
			justify-content: start;
			width: 80px;
			padding-block: 16px;
		}
		@include imago.imago-surface;
		> [role="tab"] {
			@include theme.button-text;
			color: var(--md-sys-color-on-surface-variant);
			flex: 1 1 0;
			@media (min-width: 600px) {
				flex: 0 0 auto;
				padding-block: 0;
			}
			margin: 0;
			padding-inline: 0;
			@include util.apply-typescale('md-sys-typescale-label-medium');
			display: flex;
			flex-flow: column;
			height: auto;
			gap: 4px;
			padding-block: 12px 16px;
			span.material-symbols-outlined {
				box-sizing: content-box;
				padding-block: 4px;
				padding-inline: 20px;
				border-radius: 16px;
				transition-property: color, font-variation-settings;
				transition-duration: 0.2s;
				transition-timing-function: cubic-bezier(0.2, 0.0, 0, 1.0);
			}
			&[aria-selected] {
				color: var(--md-sys-color-on-surface);
				span.material-symbols-outlined {
					font-variation-settings: 'FILL' 1, 'GRAD' 0, 'opsz' 24;
					background-color: var(--md-sys-color-secondary-container);
				}
			}
		}
	}

	> div {
		--viewport-width: 100vw;
		@media (min-width: 600px) {
			--viewport-width: calc(100vw - 80px);
		}
		flex: 1;
		overflow: hidden;
		box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .1);
		> [role="tabpanel"] {
			flex: 1;
			flex-flow: row nowrap;
			overflow: hidden;
			transition-property: margin-inline-start;
			transition-duration: 0.2s;
			transition-timing-function: cubic-bezier(0.3, 0.0, 0.8, 0.15);
			&[data-subpage-open] {
				transition-duration: 0.5s;
				transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1.0);
				margin-inline-start: calc(0px - var(--viewport-width) - 8px);
			}
			> * {
				min-width: var(--viewport-width);
				overflow-y: scroll;
			}
		}
	}

	> .sign-in, > .register {
		justify-content: center;
		> main {
			@media (min-width: 600px) {
				width: 400px;
				margin: 0 auto;
			}
		}
	}
}

p:not(.display-large) {
	max-width: 600px;
}

@media (min-width: 840px) {
	main > section, main > div > section {
		@include util.block;
		margin-inline: auto;
		width: 600px;
		> h1, > h2 {
			padding: 6px;
			margin: -6px;
		}
	}
}

@media (min-width: 1200px) {
	main > section, main > div > section {
		padding-block: 32px;
		width: auto;
		&, & > form, & > div {
			gap: 16px;
		}
	}
	main > section:not([data-done]) , main > div > section:not([data-done]) {
		display: grid;
		grid-template-columns: 260px 600px;
		grid-template-rows: auto;
		column-gap: 60px;
		> hgroup, > h1, > h2 {
			grid-column: 1 / 1;
			position: absolute;
			width: 260px;
			margin-inline: 0;
			box-sizing: content-box;
			padding: 20px;
			margin: -20px;
			flex-flow: column;
			align-items: end;
			text-align: end;
		}
		> h1 {
			background-color: var(--md-sys-color-primary);
			color: var(--md-sys-color-on-primary);
			background-clip: padding-box;
		}
		> * {
			grid-column: 2;
		}
	}
}

.calendar {
	@include imago.calendar;
}

.display-large {
	@include util.apply-typescale('md-sys-typescale-display-medium');
	color: var(--md-sys-color-primary);
}

.display-medium {
	@include util.apply-typescale('md-sys-typescale-display-small');
}

.display-small {
	@include util.apply-typescale('md-sys-typescale-headline-medium');
}

.body-medium {
	@include util.apply-typescale('md-sys-typescale-body-medium');
}

.body-small {
	@include util.apply-typescale('md-sys-typescale-body-small');
}

.label-medium {
	@include util.apply-typescale('md-sys-typescale-label-medium');
}

.label-small {
	@include util.apply-typescale('md-sys-typescale-label-small');
}

@media (min-width: 600px) {
	.display-large {
		@include util.apply-typescale('md-sys-typescale-display-large');
	}

	.display-medium {
		@include util.apply-typescale('md-sys-typescale-display-medium');
	}

	.display-small {
		@include util.apply-typescale('md-sys-typescale-display-small');
	}
}

main {
	@include imago.imago-main-on-medium;
}

article {
	@include theme.card-elevated;
	margin-block: 8px;
}

fieldset {
	@include theme.card-outlined;
	margin-block: 8px;
}

article, fieldset {
	> hgroup {
		height: 40px;
		margin-block: calc(-20px + 1.75rem/2);
	}
}

.radio-pool {
	@include util.block;
	flex-flow: row wrap;

	button {
		@include theme.button-filled;
		background-color: var(--md-extended-color-blue-color);
	}
}

aside {
	align-items: center;
	color: var(--md-sys-color-on-surface-variant);
}

.row {
	@include util.block;
	flex-flow: row wrap;
}

ul.files {
	padding-inline-start: 0;
	margin-bottom: 2px;
	li {
		display: flex;
		@include theme.card-elevated;
		padding-block: 0;
		flex-flow: row;
		align-items: center;
		span {
			flex: 1;
			margin-inline: var(--margin-inline-start);
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		button {
			@include theme.button-text;
			--margin-inline-start: 0;
			--margin-inline-end: 0;
		}
	}
}

.dev {
	background-color: var(--md-sys-color-error-container);
	color: var(--md-sys-color-on-error-container);
	padding: 8px;
	border-radius: 4px;
}

aside.dev {
	width: 100%;
	padding-block: 16px;
	@media (min-width: 600px) {
		width: 400px;
		padding-block: 32px;
	}
	align-self: center;
	align-items: stretch;
}

[data-done] {
	color: var(--md-sys-color-on-surface-variant);
	text-decoration: line-through;
}

[data-done] h2::before {
	content: "✓";
	display: inline-block;
}

ul.steps li {
	list-style-type: "☐ ";
}

.steps li[data-done] {
	list-style-type: "☑ ";
}

article ul {
	padding-left: 0;

	li {
		list-style-type: none;
	}
}

.runout {
	@include theme.card-filled;
	background-color: var(--md-sys-color-error);
	color: var(--md-sys-color-on-error);
	border-radius: 4px;
	p {
		text-align: center;
	}

	button {
		@include theme.button-filled-tonal;
		background-color: #f6f1ff;
		color: var(--md-sys-color-on-primary-container);
	}
}

article .expander {
	margin-block: -16px;
	margin-inline: 4px;
	justify-content: end;
}

.hello {
	> :first-child {
		flex: 1;
	}
}

.tf-v1-close {
	outline: none;
}

#app > .app-install-banner {
	flex: 0 0 auto;
	background: var(--md-sys-color-primary);
	padding-inline: 16px;
	p {
		flex: 1;
		@include util.apply-typescale('md-sys-typescale-label-large');
		color: var(--md-sys-color-on-primary);
	}
	button {
		color: var(--md-sys-color-on-primary);
	}

	@media (min-width: 600px) {
		display: none;
	}
}
