@use 'mdsass' as util;

@mixin imago-surface {
	@at-root :root.light-only & {
		-webkit-backdrop-filter: blur(.3rem);
		backdrop-filter: blur(.3rem);
		background-color: #f1ebff80;
		box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .1);
	}

	@media not (prefers-color-scheme: dark) {
		-webkit-backdrop-filter: blur(.3rem);
		backdrop-filter: blur(.3rem);
		background-color: #f1ebff80;
		box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .1);
	}
}

@mixin _style_main {
	padding-block: 16px;
	> * {
		--margin-inline-start: 16px;
		--margin-inline-end: 16px;
	}

	@include imago-surface;
	border-image: var(--gradient) 1;

	border-width: 4px 0;
}

@mixin _style_main_wide {
	border-width: 4px;
}

@mixin imago-main-on-medium {
	@include util.block-on-medium;
	@include _style_main;

	@media (min-width: 600px) {
		@include _style_main_wide;
	}
}
