@use "sass:meta";
@use "sass:map";

@mixin declare-typescale($typescale, $font, $weight, $weightProminent, $size, $lineHeight, $tracking) {
	--#{$typescale}--font: #{$font};
	--#{$typescale}--weight: #{$weight};
	--#{$typescale}--weight-prominent: #{$weightProminent};
	--#{$typescale}--size: #{$size * 0.0625rem};
	--#{$typescale}--line-height: #{$lineHeight * 0.0625rem};
	--#{$typescale}--tracking: #{$tracking * 0.0625rem};
}

@mixin apply-typescale($typescale) {
	font-family: var(--#{$typescale}--font);
	font-size: var(--#{$typescale}--size);
	font-weight: var(--#{$typescale}--weight);
	line-height: var(--#{$typescale}--line-height);
	letter-spacing: var(--#{$typescale}--tracking);

	strong {
		font-weight: var(--#{$typescale}--weight-prominent);
	}
}

@mixin block {
	margin-inline-start: var(--margin-inline-start);
	margin-inline-end: var(--margin-inline-end);
	> * {
		--margin-inline-start: 0px;
		--margin-inline-end: 0px;
	}
}

@mixin block-on-medium {
	@include block;

	margin-inline-start: 0;
	margin-inline-end: 0;

	@media (min-width: 600px) {
		margin-inline-start: var(--margin-inline-start);
		margin-inline-end: var(--margin-inline-end);
	}
}

@mixin block-on-expanded {
	@include block;

	margin-inline-start: 0;
	margin-inline-end: 0;

	@media (min-width: 840px) {
		margin-inline-start: var(--margin-inline-start);
		margin-inline-end: var(--margin-inline-end);
	}
}
